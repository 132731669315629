@font-face {
  font-family: "Bembo ";
  font-style: normal;
  font-display: swap;
  src: url("./BemboFont/Bembo.otf");
}
@font-face {
  font-family: "calibri";
  font-style: normal;
  /* font-weight: 400; */
  font-display: swap;
  src: url("./BemboFont/Calibri\ Regular/Calibri Regular.ttf");
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* justify-content: flex-end; */
  /* align-items: flex-end; */
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "calibri" !important;
}
p,
span {
  font-family: "calibri" !important;
  font-size: 15px;
  text-align: justify;
}
h5 {
  font-size: 15px;
}
a {
  color: black;
  font-size: 15px;
  text-align: justify;
}

.blueBtn {
  background-color: #88a5af;
  color: white;
  padding: 10px 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
}

.cardContainer {
  max-width: 700px;
  width: 90%;
  margin: 50px auto;
  border: 1px solid gray;
  padding: 30px;
}
.head {
  border-bottom: 25px solid #88a5af;
  padding-bottom: 5px;
}
.head h1 {
  font-size: 24px;
}
.boxContent h2 {
  font-size: 20px;
}
.cardBottom a {
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  color: rgb(155, 154, 154);
  font-weight: 700;
}

.boxContent {
  padding: 10px 0px;
  padding-bottom: 50px;
  border-bottom: 3px solid #88a5af;
}

.termsContent {
  margin-top: 30px;
}
.termsContent p,
.termsContent h5,
.radios {
  margin-top: 15px;
}
.radios label {
  font-size: 15px;
}

.radios input {
  margin: 0px 3px;
}
.submitBtn {
  margin-top: 15px;
  width: 42%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* document pagge  */

.docTable {
  margin: 30px 0px;
  width: 100%;
  border-collapse: collapse;
}

.font_calibr{
  font-family: "calibri" !important;
}
.docTable th:nth-of-type(1) {
  width: 200px;
}
.docTable th {
  font-size: 16px;
}
.docTable td {
  font-size: 15px;
}
.docTable th,
.docTable td {
  border-bottom: 2px solid rgb(194, 193, 193);
  padding: 8px;
  text-align: start;
}
/* .docTable a,.docContent a{
  color: black;
  font-weight: 600;
} */

.docBox {
  padding: 10px;
  /* box-shadow: 0px 0px 3px rgb(155, 154, 154); */
  border: 1px solid rgb(155, 154, 154);
  /* border-radius: 4px; */
  margin-bottom: 15px;
}

/* imprint page  */
.backBtn {
  text-align: right;
}

.imprintContent {
  margin-top: 40px;
}
.paras {
  margin-top: 20px;
}

.emailLink {
  margin-top: 20px;
}
.emailLink a {
  text-decoration: none;
  color: black;
}
.emailLink span {
  display: inline-block;
  min-width: 55px;
}

.contacts {
  margin-top: 10px;
}

/* admin panel  */

.adHead {
  text-align: center;
}
.fields {
  margin-top: 15px;
}
.pageName {
  text-align: center;
  margin: 10px 0px;
}
.fieldUp {
  box-shadow: 0px 0px 5px rgb(180, 179, 179);
  padding: 10px;
  border-radius: 4px;
  margin-top: 15px;
}
.dbCOnt,
.inpts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconBtn {
  background-color: #88a5af;
  padding: 6px 4px 4px 6px;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.inpts input,
.inpts textarea {
  width: 80%;
  padding: 6px 0px;
  border: none;
  border-bottom: 2px solid #88a5af;
  outline: none;
}
.dbCOnt iframe {
  width: 80%;
}
.dbCOnt textarea,
.paras textarea {
  width: 80%;
  padding: 6px 0px;
  font-family: inherit;
  border: none;
  /* border-bottom: 2px solid #88a5af; */
  outline: none;
  /* height: 200px; */
  min-height: 100px;
  font-size: 15px;
  text-align: justify;
  resize: none;
}
.iconsBtns {
  display: flex;
  justify-content: space-between;
  width: 12%;
}
.cnclBtn {
  background-color: gainsboro;
  color: black;
  border: none;
  outline: none;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 6px 4px 0px 4px;
  cursor: pointer;
}
.checkBtn {
  background-color: green;
  color: white;
  border: none;
  outline: none;
  border-radius: 50%;
  font-size: 1.5rem;
  padding: 6px 4px 0px 4px;
  cursor: pointer;
}

.sm_ftr {
  padding: 10px;
  background-color: #202020;
}
.sm_ftr p {
  text-align: center;
  color: #6c757d !important;
}
.sm_ftr a {
  color: #1aaca2;
  text-decoration: none;
}

html .mb {
  display: none;
}
.iframe-wrapper {
  max-height: 100vh;
  overflow: hidden;
  height: 100vh;
  display: flex;
  width: 100%;
}
@media (max-width: 800px) {
  html .desk {
    display: none;
  }
  html .mb {
    display: block;
  }
  html,
  body,
  #root,
  .App {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }
  .iframe-wrapper {
    max-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }
}

@media (max-width: 600px) {
  .inpts input,
  .inpts textarea {
    width: 70%;
  }
  .iconsBtns {
    width: 20%;
  }
  .head h1 {
    font-size: 1.3rem;
  }
}
@media (max-width: 400px) {
  .cardContainer {
    padding: 20px 10px;
    width: 95%;
  }
}
@media (max-width: 340px) {
  .inpts input,
  .inpts textarea {
    width: 70%;
  }
  .iconsBtns button {
    /* width: 30%; */
    font-size: 1rem;
  }
}
